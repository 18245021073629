<template>
  <div class="containBox">
    <!-- 步骤一 -->
    <div class="objectPresentation" v-show="step">
      <!-- 步骤条 -->
      <div class="articleStepsa">
        <el-steps :active="1" align-center>
          <!-- 选择对象与展示方式 -->
          <el-step :title="$t('label.noRules.have.been.enabled')"></el-step>
          <!-- 设置报表 -->
          <el-step
            :title="
              $t('front-lightning-module-v1-details-page-chart-set-report')
            "
          ></el-step>
        </el-steps>
      </div>
      <!-- 选择对象和记录类型 -->
      <div class="objectRecord">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <!-- 点击新建 表单输入框可编辑    点击编辑 表单输入框不可编辑 -->
          <!-- 选择对象 -->
          <el-form-item :label="$t('label.selectobject')" prop="region1">
            <el-select
              filterable
              v-model="ruleForm.region1"
              :placeholder="$t('vue_label_notice_pleaseselectobject')"
              @change="changeObjectList"
              :disabled="this.$route.query.isEdit === 'true' ? true : false"
            >
              <el-option
                v-for="item in setObjectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 记录类型 -->
          <el-form-item
            :label="$t('label.appupload.component.type.recordtype')"
            prop="region2"
          >
            <el-select
              v-model="ruleForm.region2"
              :placeholder="
                $t('front-lightning-module-v1-details-page-chart-record-type')
              "
              :disabled="
                ruleForm.region1 == '' || this.$route.query.isEdit === 'true'
                  ? true
                  : false
              "
            >
              <el-option
                v-for="item in recordTypeList"
                :key="item.oldid"
                :label="item.multiName"
                :value="item.oldid"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 步骤二 -->
    <div class="setThechartBox" v-show="!step">
      <!-- 步骤条 -->
      <div class="articleStepsb">
        <el-steps :active="2" align-center>
          <!-- 选择对象与展示方式 -->
          <el-step
            :title="$t('front-lightning-module-v1-details-page-chart-select')"
          ></el-step>
          <!-- 设置报表图表 -->
          <el-step
            :title="
              $t('front-lightning-module-v1-details-page-chart-setting-chart')
            "
          ></el-step>
        </el-steps>
      </div>
      <!-- 说明 -->
      <div class="chartInstructions">
        <div class="chartInstructionsbox">
          <P>{{
            $t("front-lightning-module-v1-details-page-chart-add-report")
          }}</P>
          <P>{{
            $t("front-lightning-module-v1-details-page-chart-title-supports")
          }}</P>
          <P>{{
            $t("front-lightning-module-v1-details-page-chart-selecting-reports")
          }}</P>
          <P>{{
            $t("front-lightning-module-v1-details-page-chart-relevant")
          }}</P>
        </div>
      </div>

      <!-- 报表图表 -->
      <div class="chartAndreport">
        <vuedraggable v-model="reportChartData" @update="reportDraggable">
          <div
            class="reportContent"
            v-for="(item, index) in reportChartData"
            :key="index"
          >
            <!-- 叉号 -->
            <div
              class="chahao"
              @click="delebtnMth(index)"
              v-show="reportChartData.length > 1"
            >
              <svg class="icon" aria-hidden="true">
                <use href="#icon-a-Group90"></use>
              </svg>
            </div>
            <!-- 活动名称和报表 -->
            <el-form
              :model="item"
              :rules="rules2"
              ref="wzj"
              class="demo-ruleForm"
            >
              <!--标题  -->
              <el-form-item
                :label="$t('label.titlename')"
                prop="title"
                label-width="80px"
              >
                <el-input v-model="item.title"></el-input>
              </el-form-item>
              <!-- 选择报表 -->
              <el-form-item
                :label="
                  $t(
                    'front-lightning-module-v1-details-page-chart-select-statements'
                  )
                "
                prop="reportid"
                label-width="103px"
              >
                <el-cascader
                  v-model="item.reportid"
                  :options="options"
                  @change="
                    (val) => {
                      reportChange(val, index);
                    }
                  "
                  placeholder=""
                >
                </el-cascader>
              </el-form-item>
            </el-form>
            <!-- 筛选依据 -->
            <div class="screeningBox">
              <!-- <el-checkbox v-model="item.screening">筛选依据</el-checkbox> -->
              <span>{{
                $t(
                  "front-lightning-module-v1-details-page-chart-screening-based-on"
                )
              }}</span>
              <el-select
                v-model="item.screening"
                placeholder=""
                :disabled="item.whetherDisableScreening"
              >
                <!-- 无 -->
                <el-option
                  :label="$t('label.tabpage.nothingz')"
                  :value="$t('label.tabpage.nothingz')"
                ></el-option>
                <el-option
                  :label="
                    $route.query.isEdit === 'true'
                      ? $route.query.isEditMode.objectname + 'ID'
                      : chooseObject + 'ID'
                  "
                  :value="
                    $route.query.isEdit === 'true'
                      ? $route.query.isEditMode.objid
                      : chooseObjectId
                  "
                ></el-option>
              </el-select>
            </div>
            <!-- 刷新按钮 -->
            <div class="refreshBox">
              <!-- 显示刷新按钮 -->
              <el-checkbox v-model="item.isshow">{{
                $t(
                  "front-lightning-module-v1-details-page-chart-refresh-button"
                )
              }}</el-checkbox>
            </div>
          </div>
        </vuedraggable>
        <!-- 新建添加报表图表 -->
        <div
          class="addNewReport"
          v-show="reportChartData.length < 10"
          @click="addsomething"
        >
          <div class="xinjian">+</div>
        </div>
      </div>
    </div>
    <!-- 上一步 下一步 取消 保存 按钮 -->
    <div class="operatingButton">
      <!--步骤一  取消  下一步 -->
      <el-button plain class="cancleBtn" @click="cancleBtn" v-show="step">{{
        $t("label.emailtocloudcc.button.back")
      }}</el-button>
      <el-button
        type="primary"
        plain
        class="rightStepBtn"
        v-show="step"
        @click="nextStepBtn('ruleForm')"
        >{{ $t("label.import.page1.nextstep") }}</el-button
      >
      <!--步骤二  上一步  保存 -->
      <el-button
        plain
        class="previousStepBtn"
        @click="previousStepMeth"
        v-show="!step"
        >{{ $t("label.import.page1.laststep") }}</el-button
      >
      <el-button
        type="primary"
        plain
        class="rightStepBtn"
        @click="saveMeth('wzj')"
        v-show="!step"
        >{{ $t("label.chatter.save") }}</el-button
      >
    </div>
  </div>
</template>
<script>
import vuedraggable from "vuedraggable";
import * as pageChartApi from "../../systemSettingsApi.js";
export default {
  name: "reportChart",
  components: {
    vuedraggable,
  },
  data() {
    return {
      reportId: this.$route.query.reportId, // 报表id 编辑时使用
      // 步骤一
      ruleForm: {
        region1:
          this.$route.query.isEdit === "true"
            ? this.$route.query.isEditMode.objid
            : "",
        region2:
          this.$route.query.isEdit === "true"
            ? this.$route.query.isEditMode.recordtypename
            : "",
      },
      // 选择对象和记录类型 下拉框校验   this.$i18n.t('label.readonly')
      rules: {
        region1: [
          {
            required: true,
            message: this.$i18n.t("vue_label_notice_pleaseselectobject"),
            trigger: "change",
          },
        ], //请选择对象
        region2: [
          {
            required: true,
            message: this.$i18n.t(
              "front-lightning-module-v1-details-page-chart-record-type"
            ),
            trigger: "change",
          }, //请选择记录类型
        ],
      },
      step: true, //控制步骤一 二 的显示隐藏
      setObjectList: [], //选择对象的下拉列表全部数据
      recordTypeList: [], //记录类型的下拉列表全部数据
      chooseObject: "", //步骤一所选 对象名称
      chooseObjectId: "", //步骤一所选 对象id
      reportInfo: {
        title: "",
        reportid: "",
        screening: this.$i18n.t('label.tabpage.nothingz'),        //无
        isshow: false,
        reportname: "",
        whetherDisableScreening: true,
        sort: "",
      },
      // 步骤二  编辑框内  默认数据
      reportChartData: [],
      // 设置报表图表 标题 校验
      rules2: {
        title: [
          {
            max: 50,
            message: this.$i18n.t(
              "front-lightning-module-v1-details-page-chart-maximum"
            ),
            trigger: "blur",
          },
        ], //最多输入50个字符
        reportid: [
          { required: true, message: "请选择报表", trigger: "change" },
        ],
      },
      //  报表图表次级选择器 所有数据
      options: [], //报表选择框数据
      allReportChartListArr: [], //全部报表图表列表数据
      // editReportId: "", //编辑报表的id
      ObjectByReportidArr: [], //根据报表id查询报表所包含的对象
    };
  },
  watch: {},
  mounted() {
    this.getReportChartList();
  },
  created() {
    this.reportChartData = [];
    let reportInfo = JSON.parse(JSON.stringify(this.reportInfo));
    reportInfo.sort = this.reportChartData.length;
    this.reportChartData.push(reportInfo);

    this.init();
  },
  methods: {
    init() {
      // 获取选择对象选择框数据的列表
      this.getObjectList();
      // 获取报表选择框数据的列表
      this.getreportInformationList();
      // 如果报表id有值，说明正在编辑，根据报表id查询数据
      if (this.reportId !== undefined) {
        this.getReportChartDetail(this.reportId);
        this.editRecordtypeId = this.$route.query.isEditMode.recordtype;
      }
    },
    // 获取报表编辑回显数据
    async getReportChartDetail(reportId) {
      let params = {
        id: reportId,
      };
      let res = await pageChartApi.getReportChartDetail(params);
      if (res.result) {
        this.reportChartData = res.data;
        this.reportChartData.forEach((item) => {
          // 回显是否显示刷新按钮
          if (item.isshow === "true") {
            this.$set(item, "isshow", true);
          } else {
            this.$set(item, "isshow", false);
          }
          // 回显是否禁用筛选依据
          if (item.whetherdisablescreening === "true") {
            this.$set(item, "whetherDisableScreening", true);
          } else {
            this.$set(item, "whetherDisableScreening", false);
          }
        });
      }
    },
    // 获取详情页报表图表全部列表信息
    async getReportChartList() {
      let res = await pageChartApi.getReportChartList();
      if (res.result) {
        this.allReportChartListArr = res.data;
      }
    },
    // 获取选择对象选择框数据的列表
    async getObjectList() {
      let op = {};
      let res = await pageChartApi.getObjectListA(op);
      if (res.result) {
        this.setObjectList = res.data;
      }
    },
    // 获取记录类型选择框数据列表
    async getRecordTypeList(id) {
      let params = {
        objId: id,
      };
      let res = await pageChartApi.getRecordTypeList(params);
      if (res.result) {
        this.recordTypeList = res.data;
        // 筛选  所有对象下  已有对应配置的  记录类型  置灰
        this.allReportChartListArr.forEach((val) => {
          if (val.objid === id) {
            this.recordTypeList.forEach((item) => {
              if (val.recordtype === item.oldid) {
                item["disabled"] = true;
              }
            });
          }
        });
      }
    },

    // 更改选择对象列表数据
    changeObjectList(id) {
      this.getRecordTypeList(id);
      this.ruleForm.region2 = "";
      this.setObjectList.forEach((item) => {
        if (item.id === id) {
          this.chooseObject = item.name;
          this.chooseObjectId = item.id;
        }
      });
    },
    // 取消
    cancleBtn() {
      this.$router.go(-1);
    },
    // 下一步
    nextStepBtn(wj) {
      this.$refs[wj].validate((valid) => {
        if (valid) {
          this.step = false;
        } else {
          return false;
        }
      });
    },
    // 获取报表选择框数据的列表
    async getreportInformationList() {
      let params = {};
      let res = await pageChartApi.getreportInformationList(params);
      if (res.result) {
        this.options = res.data.options;
      }
    },

    // 根据报表id查询报表所包含的对象
    async getObjectByReportid(id, choseIndex) {
      let params = {
        reportid: id,
      };
      let res = await pageChartApi.getObjectByReportid(params);
      if (res.result) {
        this.ObjectByReportidArr = res.data;
        this.reportChartData.forEach((item, index) => {
          if (choseIndex === index) {
            // 无
            this.$set(item, "screening", this.$i18n.t('label.tabpage.nothingz'));
            if (
              this.ObjectByReportidArr.some(
                (data) => data.projectid === this.ruleForm.region1
              )
            ) {
              this.$set(item, "whetherDisableScreening", false);
            } else {
              this.$set(item, "whetherDisableScreening", true);
            }
          }
        });
      }
    },
    // 报表图表选择
    reportChange(val, index) {
      this.getObjectByReportid(val[1], index);
    },
    // 拖拽报表详情 更改序号
    reportDraggable() {
      // 拖拽后重新排序 报表详情
      this.$nextTick(() => {
        this.reportChartData.forEach((item, index) => {
          item.sort = index;
        });
      });
    },
    // 新建添加
    addsomething() {
      if (this.reportChartData.length > 9) {
        this.$message(this.$i18n.t("label.most.setup.obj"));
      } else {
        let reportInfo = JSON.parse(JSON.stringify(this.reportInfo));
        reportInfo.sort = this.reportChartData.length;
        this.reportChartData.push(reportInfo);
      }
    },
    // 叉号删除
    delebtnMth(m) {
      this.reportChartData.splice(m, 1);
      // 删除后重新排序 报表详情
      this.$nextTick(() => {
        this.reportChartData.forEach((item, index) => {
          item.sort = index;
        });
      });
    },
    // 上一步
    previousStepMeth() {
      this.step = true;
    },
    // 详情页报表图表保存接口
    async saveReportChart() {
      //   配置报表图表数据
      let newChartData = [];
      this.reportChartData.forEach((item) => {
        //  this.editReportId = item.id;
        if (item.detailid) {
          // 删除接口返回不需要的属性
          this.$delete(item, "objectname");
          this.$delete(item, "objid");
          this.$delete(item, "recordtype");
          this.$delete(item, "recordtypename");
          this.$delete(item, "id");
          this.$delete(item, "whetherdisablescreening");
        }
        if (item.reportid instanceof Array) {
          item.reportid = item.reportid[1];
        }
        newChartData.push(Object.assign(item));
      });

      // 赋值对应的报表 名称
      this.options.forEach((item) => {
        item.children.forEach((val) => {
          newChartData.forEach((data) => {
            if (data.reportid === val.value) {
              data.reportname = val.label;
            }
          });
        });
      });

      //  选择对象与记录类型数据
      let editObjRecord = {
        id: this.reportId !== undefined ? this.reportId : "",
        objid: this.ruleForm.region1,
        recordtype:
          this.reportId !== undefined
            ? this.editRecordtypeId
            : this.ruleForm.region2,
      };
      let params = {
        data: JSON.stringify(editObjRecord),
        chartData: JSON.stringify(newChartData),
      };
      let maxtitle = 0;
      newChartData.forEach((val) => {
        val.title = val.title === null ? "" : val.title;
        maxtitle = maxtitle > val.title.length ? maxtitle : val.title.length;
      });
      let noreport = false;
      newChartData.forEach((val) => {
        if (val.reportid === "") {
          noreport = true;
        }
      });
      if (maxtitle > 50) {
        return false;
      } else if (noreport === true) {
        this.$message({
          message: this.$i18n.t("vue_label_dashboard_selectreport"),
          type: "warning",
        });
      } else {
        let res = await pageChartApi.saveReportChart(params);
        if (res.result) {
          // 跳转到展示报表图表页面
          this.$router.push({
            path: "detailsPageChart",
          });
        }
      }
    },
    // 保存
    saveMeth() {
      // 详情页报表图表保存
      this.saveReportChart();
    },
  },
};
</script>
<style lang="scss" scoped>
.containBox {
  width: 100%;
  height: 100%;
  position: relative;
  .objectPresentation {
    width: 100%;
    height: 100%;
    // 步骤条
    .articleStepsa {
      width: 100%;
      padding: 20px 0;
    }
    // 选择对象和记录类型
    .objectRecord {
      width: 100%;
      padding: 20px 0;
      // form表单样式
      ::v-deep .demo-ruleForm {
        width: 40%;
        margin: auto;
        // 代码集/单选框样式
        .codeSetClass,
        .radioClass {
          margin-bottom: 8px !important;
        }
        // 单选框
        .radioClass {
          .el-radio-group {
            margin-left: -86px;
            .el-radio:first-of-type {
              margin-right: 60px;
            }
          }
        }
        .el-form-item {
          img {
            display: inline-block;
            margin-left: 10px;
          }
          margin-bottom: 20px;
          // label
          .el-form-item__label {
            font-size: 14px;
            text-align: right;
            font-weight: bold;
            color: #080707;
            // margin-left: 20px;
          }
          // content
          .el-form-item__content {
            margin-left: 130px !important;
            .el-textarea {
              width: 319px;
            }
            .el-input {
              width: 319px;
              height: 30px;
              .el-input__inner {
                height: 30px;
                line-height: 30px;
              }
              .el-input__suffix {
                .el-input__icon {
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  .setThechartBox {
    width: 100%;
    height: 88%;
    overflow-y: auto;
    // position: relative;
    // 步骤条
    .articleStepsb {
      width: 100%;
      padding: 20px 0 10px 0;
    }
    // 说明
    .chartInstructions {
      width: 100%;
      .chartInstructionsbox {
        width: 70%;
        margin: auto;
        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #333333;
        }
      }
    }
    // 报表图表
    .chartAndreport {
      width: 100%;
      // height: 340px;
      // overflow-y: auto;
      // border: solid 1px;
      // padding: 10px 0;
      .reportContent {
        width: 466px;
        position: relative;
        border: solid 1px #dedcda;
        border-radius: 3px;
        margin: 15px auto;
        padding: 20px 10px;
        cursor: pointer;
        font-size: 12px;
        color: #333333;
        .chahao {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
          .icon {
            width: 18px;
            height: 18px;
            display: inline-block;
          }
        }
        // form表单样式
        ::v-deep .demo-ruleForm {
          width: 100%;
          margin: auto;
          // 代码集/单选框样式
          .codeSetClass,
          .radioClass {
            margin-bottom: 8px !important;
          }
          // 单选框
          .radioClass {
            .el-radio-group {
              margin-left: -86px;
              .el-radio:first-of-type {
                margin-right: 60px;
              }
            }
          }
          .el-form-item {
            img {
              display: inline-block;
              margin-left: 10px;
            }
            margin-bottom: 20px;
            // label
            .el-form-item__label {
              // font-size: 14px;
              // text-align: left;
              // font-weight: bold;
              // color: #080707;
              // margin-left: 20px;
            }
            // content
            .el-form-item__content {
              margin-left: 130px !important;
              .el-textarea {
                width: 319px;
              }
              .el-input {
                width: 280px;
                height: 30px;
                .el-input__inner {
                  height: 30px;
                  line-height: 30px;
                }
                .el-input__suffix {
                  .el-input__icon {
                    line-height: 30px;
                  }
                }
              }
            }
          }
        }
        .screeningBox {
          width: 100%;
          padding: 5px 0 10px 44px;
          ::v-deep .el-input {
            width: 280px;
            height: 30px;
            margin-left: 37px;
            .el-input__inner {
              height: 30px;
              line-height: 30px;
            }
            .el-input__suffix {
              .el-input__icon {
                line-height: 30px;
              }
            }
          }
        }
        .refreshBox {
          width: 100%;
          padding: 10px 0 10px 44px;
        }
        .el-input__suffix {
          .el-input__icon {
            line-height: 30px;
          }
        }
      }
    }
    .refreshBox {
      width: 100%;
      padding: 10px 0 10px 44px;
    }
  }
  // 新建添加报表图表
  .addNewReport {
    width: 100%;
    border-top: 1px solid #dddbda;
    position: absolute;
    bottom: 3%;
    text-align: right;
    height: 40px;
    ::v-deep .el-button {
      width: 74px;
      height: 28px;
      padding: 0;
      margin-top: 15px;
    }
    .rightStepBtn {
      margin-right: 36px;
    }
  }
}
.operatingButton {
  width: 100%;
  border-top: 1px solid #dddbda;
  position: absolute;
  bottom: 3%;
  text-align: right;
  height: 40px;
  ::v-deep .el-button {
    width: 74px;
    height: 28px;
    padding: 0;
    margin-top: 15px;
  }
  .rightStepBtn {
    margin-right: 36px;
  }
}
</style>